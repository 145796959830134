import axios from 'axios';
import './staking.css';
// import Finished from './categories/Finished';
// import Paused from './categories/Paused';
// import Live from './categories/Live';
import Countdown from 'react-countdown';
import {useConnectWallet} from '@web3-onboard/react';
import {ethers} from 'ethers';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {saveCollection} from '../../redux/features/nftSlice';
import {stakingContractInstByAddr} from '../../contracts/contractInstance';
import {stakingContractAbi} from '../../contracts/stakingContract';

export default function Staking() {
  const {nft} = useSelector((state) => state);
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('live');
  const [{wallet}] = useConnectWallet();
  const [allNfts, setAllNfts] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const currTime = Math.floor(Date.now());
  const [sortBy, setSortBy] = useState('');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (nft?.collection?.length > 0) {
      setAllNfts(nft?.collection);
      setApiData(nft?.collection);
    } else {
      handleGetNfts();
    }

    return () => {};
  }, [wallet, nft?.collection]);

  const handleGetNfts = async () => {
    try {
      setIsLoading(true);
      let url = `${process.env.REACT_APP_API_URL}nfts`;
      let _nfts = await axios.get(url).then((res, err) => {
        if (err) {
          console.log('🚀 ~ let_nfts=awaitaxios.get ~ err', err);
        } else {
          return res;
        }
      });
      if (_nfts) {
        setIsLoading(false);
        setAllNfts(_nfts.data);
        setApiData(_nfts?.data);
        // getTotalNftStaked(_nfts.data);
        dispatch(saveCollection(_nfts?.data));
      }
    } catch (error) {
      setIsLoading(false);

      console.log('🚀 ~ handleGetNfts ~ error', error);
    }
  };

  // const handleNftFilter=()=>{

  // }
  const renderer = ({days, hours, minutes, seconds, completed}) => {
    if (completed) {
      return (
        <span className="complete_countdown_text">Countdown complete!</span>
      );
    } else {
      return (
        <span className="time_part">
          <span className="mytime"> {days}</span> :{' '}
          <span className="mytime">{hours}</span> :{' '}
          <span className="mytime">{minutes}</span> :{' '}
          <span className="mytime">{seconds}</span>
        </span>
      );
    }
  };

  const handleSortNft = (val) => {
    // console.log('🚀 ~ handleSortNft ~ val', val);
    // console.log('allnfts', allNfts);
    // console.log('🚀 ~before handleSortNft ~ allNfts', allNfts);
    if (val === 'Sort by') {
      setSortBy(val);

      return '';
    }
    let sortByAsc = [];
    if (val === 'asc') {
      // console.log('🚀 ~ handleSortNft ~ desc', 'asc');

      sortByAsc = allNfts
        .slice()
        .sort((e, i) => parseInt(e?.expire_date) - parseInt(i?.expire_date));
      // console.log('🚀 ~ handleSortNft ~ sortByAsc', sortByAsc);
    }
    if (val === 'desc') {
      // console.log('🚀 ~ handleSortNft ~ desc', 'desc');
      sortByAsc = allNfts
        .slice()
        .sort((e, i) => parseInt(i?.expire_date) - parseInt(e?.expire_date));
      // console.log('🚀 ~ handleSortNft ~ sortByAsc', sortByAsc);
    }
    setAllNfts(sortByAsc);

    setSortBy(val);
  };
  const handleNftSearch = (text) => {
    setSearchText(text);
    if (text.length > 0) {
      // console.log('🚀 ~ handleNftSearch ~ text', text);
      const matchingObjects = allNfts.filter((object) => {
        for (const prop in object) {
          const propValue = object[prop].toString()?.toLowerCase();
          // console.log('🚀 ~ matchingObjects ~ propValue', propValue)
          for (const keyword of [text]) {
            if (propValue.includes(keyword?.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      });
      if (matchingObjects.length > 0) {
        setAllNfts(matchingObjects);
      }
      // console.log('🚀 ~ matchingObjects ~ matchingObjects', matchingObjects);
    } else {
      setAllNfts(apiData);
    }
  };

  const extractImageId = (addr) => {
    // console.log('🚀 ~ extractImageId ~ addr', addr)
    let url = addr.split('/')[5];
    // console.log('🚀 ~ extractImageId ~ url', url)
    return url;
  };
  return (
    <section className="staking-area">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col pb-5">
            <h2 className="text-center h1Text textColor">ROO STAKING</h2>
          </div>
        </div>
        {/* //card section  */}
        <div className="container">
          <div className="row d-flex justify-content-md-start justify-content-sm-between justify-content-between ">
            <div className="col-5 mx-md-2 p-0 pb-2 col-sm-4 col-md-2 col-lg-2 d-flex justify-content-center">
              <button
                className={` livFinishedBtn ${
                  activeTab == 'live' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('live')}
              >
                Live
              </button>
            </div>
            <div className="col-5 mx-md-2 p-0 pb-2 col-sm-4 col-md-2 col-lg-2 d-flex justify-content-center">
              <button
                className={` livFinishedBtn ${
                  activeTab == 'finished' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('finished')}
              >
                Finished
              </button>
            </div>
            <div className="col-12 mx-md-2 p-0 pb-2 col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center">
              <select
                onChange={(e) => handleSortNft(e.target.value)}
                class=" mySelectBox"
                aria-label="Default select example"
                value={sortBy}
              >
                <option selected>Sort by</option>
                <option value="asc">Almost Finished</option>
                <option value="desc">Longest time available</option>
              </select>
            </div>
            <div className="col-12 p-0 pb-2 col-sm-12 col-md-2  col-lg-2 d-flex justify-content-center">
              <form class="myNftSearchBox" role="search">
                <input
                  class="form-control "
                  type="search"
                  placeholder="Search Nft"
                  aria-label="Search"
                  onChange={(e) => handleNftSearch(e.target.value)}
                />
              </form>
            </div>
          </div>
        </div>

        {/* live  */}

        {activeTab === 'live' ? (
          <div className="container-fluid p-0 py-4">
            <div className="row px-2">
              <div className="col-md-12 p-0">
                <div className="row d-flex justify-content-center">
                  {isLoading ? (
                    <div className="col pt-5 mt-5 d-flex justify-content-center align-content-lg-center">
                      <span class="loader"></span>
                    </div>
                  ) : (
                    <>
                      {allNfts.length > 0 ? (
                        allNfts
                          .filter((a) => a.expire_date > currTime)
                          .map((e, i) => {
                            const futureDate = e?.expire_date
                              ? e?.expire_date > currTime
                              : true;

                            if (true) {
                              return (
                                <div
                                  className="col-12 col-md-4 col-sm-3 myCol col-md-2 col-lg-2 mb-3 p-0 px-2 myColDiv"
                                  key={i}
                                >
                                  <Link
                                    class="  "
                                    to={{
                                      pathname: `/${e?.contract_address}`,
                                      state: e // your data array of objects
                                    }}
                                  >
                                    <div class="card myCardDesign">
                                      <div>
                                        <img
                                          src={
                                            e?.picture
                                              ? `https://drive.google.com/thumbnail?id=${extractImageId(
                                                  e?.picture
                                                )}`
                                              : '/img/placeholder.png'
                                          }
                                          class="  myStakingImg myNftImage"
                                          alt="img"
                                        />
                                      </div>
                                      <div
                                        class="card-body pt-2"
                                        style={{
                                          height: '10px'
                                        }}
                                      >
                                        <h5 class="card-title py-2 mb-0">
                                          {e?.name}
                                        </h5>
                                        {/* <div className="row px-0 py-2">
                                        <div className="col-8 px-1">
                                          <p> Total Staked :</p>
                                        </div>
                                        <div className="col-3">
                                        
                                          <p>0</p>{' '}
                                        </div>
                                      </div> */}
                                        <small class="card-text small myCountown">
                                          {e?.expire_date && (
                                            <Countdown
                                              date={
                                                new Date(
                                                  parseInt(e?.expire_date)
                                                )
                                              }
                                              intervalDelay={0}
                                              precision={3}
                                              renderer={renderer}
                                            />
                                          )}
                                        </small>
                                      </div>
                                      <div className="pt-1 ">
                                        <Link
                                          class=" nftRouteBtn "
                                          to={{
                                            pathname: `/${e?.contract_address}`,
                                            state: e // your data array of objects
                                          }}
                                        >
                                          Start
                                        </Link>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              );
                            }
                          })
                      ) : (
                        <div className="col pt-5 mt-5 d-flex justify-content-center align-content-lg-center">
                          <h3>No NFT's Found</h3>
                          {/* <span class="loader"></span> */}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}

        {/* //finished  */}
        {activeTab === 'finished' ? (
          <div className="container-fluid p-0 py-4">
            <div className="row px-2">
              <div className="col-md-12 p-0">
                <div className="row d-flex justify-content-center">
                  {isLoading ? (
                    <div className="col pt-5 mt-5 d-flex justify-content-center align-content-lg-center">
                      <span class="loader"></span>
                    </div>
                  ) : (
                    <>
                      {allNfts.length > 0 ? (
                        allNfts.map((e, i) => {
                          const futureDate = parseInt(e?.expire_date);
                          if (futureDate < currTime) {
                            return (
                              <div
                                className="col-12 col-md-4 col-sm-3 myCol col-md-2 col-lg-2 mb-3 p-0 px-2 myColDiv"
                                key={i}
                              >
                                <Link
                                  class="  "
                                  to={{
                                    pathname: `/${e?.contract_address}`,
                                    state: e // your data array of objects
                                  }}
                                >
                                  <div class="card myCardDesign">
                                    <div>
                                      <img
                                        src={
                                          e?.picture
                                            ? `https://drive.google.com/thumbnail?id=${extractImageId(
                                                e?.picture
                                              )}`
                                            : '/img/placeholder.png'
                                        }
                                        class="  myStakingImg myNftImage"
                                        alt="img"
                                      />
                                    </div>
                                    <div class="card-body">
                                      <h5 class="card-title">{e?.name}</h5>
                                      {/* <div className="row">
                                 <div className="col">Total Staked</div>
                                 <div className="col">
                                 </div>
                               </div> */}
                                      <small class="card-text small myCountown">
                                        {e?.expire_date && (
                                          <Countdown
                                            date={
                                              new Date(parseInt(e?.expire_date))
                                            }
                                            intervalDelay={0}
                                            precision={3}
                                            renderer={renderer}
                                          />
                                        )}
                                      </small>
                                    </div>
                                    <div className="pt-1 ">
                                      <Link
                                        class=" nftRouteBtn "
                                        to={{
                                          pathname: `/${e?.contract_address}`,
                                          state: e // your data array of objects
                                        }}
                                      >
                                        Start
                                      </Link>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="col pt-5 mt-5 d-flex justify-content-center align-content-lg-center">
                          <h3>No NFT's Found</h3>
                          {/* <span class="loader"></span> */}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      {/* <div className="">
                <div className="row ">
                  <div className="col ">
                    <button className=" btn btn-bordered">Live</button>
                  </div>
                  <div className="col">
                    <button className=" btn btn-bordered">Finished</button>
                  </div>
                </div>
                <div className="row py-4">
                  <div className="col">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Sort by</option>
                      <option value="1">Almost Finished</option>
                      <option value="2">Two</option>
                      <option value="3">Longest time available</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <form class="d-flex" role="search">
                      <input
                        class="form-control me-2"
                        type="search"
                        placeholder="Search Name, Address"
                        aria-label="Search"
                      />
                    </form>
                  </div>
                </div>
                <div className="row py-4">
                  <div className="col">
                    <select class="selectpicker" data-live-search="true">
                      <option data-tokens="ketchup mustard">
                        Search Collection
                      </option>
                      <option data-tokens="mustard">Cat</option>
                      <option data-tokens="frosting">Dog</option>
                    </select>
                  </div>
                </div>
              </div> */}
    </section>
  );
}

import Header from './components/Header/Header';
import MyRoutes from './components/routers/routes';
import {useEffect} from 'react';
// import backgroundImage from '../src/images/3.jpeg';
import Footer from './components/Footer/Footer';
import './App.css';
import Moralis from 'moralis';

function App() {
  const appStyle = {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed' // added this line
    // backgroundColor: '#245d92'
  };



  useEffect(() => {
    startMoralis();
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', () => {
        window.location.reload();
      });
    }
  });
  const startMoralis = async () => {
    try {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS_KEY
      });
    } catch (error) {
      console.log('🚀 ~ startMoralis ~ error', error);
    }
  };

  return (
    <div style={appStyle}>
      <Header />
      <MyRoutes />
      <Footer />
    </div>
  );
}

export default App;

import React, {useEffect, useState} from 'react';
import {getJSON} from '../../common/utils';
import './nftCard.css';
export default function NftCard({item, selectedNfts, cardSelectHandle}) {
  const [imageUri, setImageUri] = useState('');
  const [selected, setSelected] = useState(false);
  const [disableNfts, setdisableNfts] = useState([]);
  const [alStaked, setAlstaked] = useState({});

  const selectorhandler = (id) => {
    if (selectedNfts.length > 39) {
      setSelected(false);
      cardSelectHandle(id);
    } else {
      setSelected(!selected);
      cardSelectHandle(id);
    }
  };

  // useEffect(() => {
  //   if (item?.token_uri) {
  //     const image = getJSON(item.token_uri);
  //     image.then((data) => {
  //       let cleanUri = data.image.replace('ipfs://', 'https://ipfs.io/ipfs/');
  //       setImageUri(cleanUri);
  //     });
  //   }
  //   // disableNft();
  //   // alreadyStakeNfts();
  // }, [item]);

  useEffect(() => {
    if (item.token_uri) {
      const image = getJSON(item.token_uri);
      image.then((data) => {
        let cleanUri =
          data.image.replace(
            'ipfs://',
            'https://roofinance.mypinata.cloud/ipfs/'
          ) +
          `?pinataGatewayToken=${process.env.REACT_APP_PINATA_GATEWAY_TOKEN}`;
        setImageUri(cleanUri);
        // console.log('🚀 ~ image.then ~ cleanUri', cleanUri);
      });
    }
   
  }, [item]);

  const cardSelectionHandler = (val) => {
    // let _dis = disableNfts.includes(item.token_id);

    // if (_dis || val?.target?.innerText === 'Someone Staked') {
    //   return null;
    // }
    selectorhandler(item.token_id);
  };
  return (
    <>
      <div className="card myNftCards" onClick={(e) => cardSelectionHandler(e)}>
        <img
          src={imageUri ? imageUri : '/img/placeholder.png'}
          style={{height: 'auto', width: '100%', borderRadius: '8px'}}
          alt={item?.name}
        />

        <div className="row  py-1">
          <div className="col p-0 text-start align-items-start">
            <p
              className="m-0 py-1 pl-2 text-start text-light fontSizeSm"
              style={{
                textAlign: 'start'
              }}
            >
              {item?.name}
            </p>
          </div>
          <div className="col-4 p-0 pt-1 pr-1">
            <p className="fontSizeSm  border border-secondary rounded text-light">
              #{item?.token_id}
            </p>{' '}
          </div>
        </div>
        {/* <p className="m-0 py-1 ">
          Token ID : <span className="text-light">  </span>
        </p> */}

        {selectedNfts.includes(item.token_id) && (
          <div
            style={{
              alignItems: 'center',
              backgroundColor: 'rgba(0, 10, 48,0.7)',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              left: '0',
              position: 'absolute',
              top: '0',
              width: '100%',
              '& p': {
                backgroundColor: '#0047ff',
                borderRadius: '8px',
                color: '#fff',
                fontSize: '20px',
                padding: '1px 10px'
              }
            }}
          >
            <p
              style={{
                // backgroundColor: '#0047ff',
                borderRadius: '8px',
                color: '#fff',
                padding: '1px 10px'
              }}
            >
              Selected
            </p>
          </div>
        )}
      </div>
    </>
  );
}

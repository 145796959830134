import React, {useEffect, useState} from 'react';
import './claim.css';
import {useConnectWallet} from '@web3-onboard/react';
import {ToastContainer, toast} from 'react-toastify';
import {
  stakingContractInstByAddr,
  tokenContractInst
} from '../../../contracts/contractInstance';

export default function Claim({stakingContractAddr, collectionAddr}) {
  const [{wallet}] = useConnectWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [symbol, setSymbol] = useState('');
  const [decimal, setDecimal] = useState('');
  const [rewardBalance, setRewardBalance] = useState('00.00');
  const [myLoader, setMyLoader] = useState('');

  const handleClaim = async () => {
    if (rewardBalance < 1) {
      toast.error("You don't have enough rewards to claim!");
    }
    try {
      setIsLoading(true);
      const _stakingContract = await stakingContractInstByAddr(
        wallet?.provider,
        stakingContractAddr
      );

      const _claim = await _stakingContract.getReward();
      let wait = await _claim.wait();
      if (wait) {
        toast.success('Claim Success');
        handleRewardsToken();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error('Something went wrong!');

      console.log('🚀 ~ handleClaim ~ error', error);
    }
  };
  const handleEarnedReward = async () => {
    try {
      const _stakingContract = await stakingContractInstByAddr(
        wallet?.provider,
        stakingContractAddr
      );
      let reward = await _stakingContract.earned(
        collectionAddr,
        wallet?.accounts[0].address
      );
      // console.log('🚀 ~ handleEarnedReward ~ reward', reward?.toString());
    } catch (error) {
      console.log('🚀 ~ handleEarnedReward ~ error', error);
    }
  };
  useEffect(() => {
    if (wallet) {
      handleEarnedReward();
      handleRewardsToken();
    }
  }, [wallet?.accounts[0].address]);

  const handleRewardsToken = async () => {
    try {
      let stakeContract = await stakingContractInstByAddr(
        wallet?.provider,
        stakingContractAddr
      );
      let reward = await stakeContract.rewardsToken();

      if (reward) {
        setMyLoader('rewardBalance')
        let tokenContractIn = await tokenContractInst(wallet?.provider, reward);
        let symbol = await tokenContractIn.symbol();
        let decimals = await tokenContractIn.decimals();
        let rewardBalance = await stakeContract.earned(
          collectionAddr,
          wallet?.accounts[0].address
        );
        
        setSymbol(symbol);
        setDecimal(decimals);
        setRewardBalance((rewardBalance / 10 ** decimals)?.toFixed(2));
        setMyLoader('')

      }
    } catch (error) {
      console.log('🚀 ~ handleRewardsToken ~ error', error);
      setMyLoader('')
    
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container pt-2">
        <div className="myClaimDiv">
          <div className="row d-flex justify-content-center ">
            <div className="col-12 col-md-6  myClaimBox px-0 py-2 shadow-lg">
              <div className="row pt-3">
                <div className="col-7 col-md-6 ">
                  <p className="m-0 px-2 text-start ">Reward Balance</p>
                </div>
                <div className="col-5 col-md-6 d-flex justify-content-end">
                  <p className="m-0 px-2   text-light pr-4 d-flex align-content-center">
                    {myLoader==='rewardBalance'? <div className='smallLoader'></div>:<>{rewardBalance ?? '00.00'} {symbol ?? ''}</>  } 
                  </p>
                </div>
              </div>
              <div className="row pt-4 pb-2 ">
                {/* <div className="col-6 d-flex align-items-center">
                  <p className="m-0 px-2 text-start text-light">
                  </p>
                </div> */}
                <div className="col-12  d-flex justify-content-center mt-3">
                  <button
                    className="oneBtn"
                    disabled={isLoading}
                    onClick={handleClaim}
                  >
                    {isLoading ? (
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden"></span>
                      </div>
                    ) : (
                      'Claim'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';

export default function Transactions({collectionAddr}) {
  // console.log('🚀 ~ Transactions ~ collectionAddr', collectionAddr);
  const [transactions, setTransactions] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    getTransactions();
    return () => {};
  }, []);
  const getTransactions = async () => {
    try {
      setLoader(true);

      let stakeTransactionUrl = `${process.env.REACT_APP_API_URL}transactions`;
      let _tx = await axios.get(stakeTransactionUrl).then((res, err) => {
        console.log('🚀 ~ let_tx=awaitaxios.get ~ err', err);
        // console.log('🚀 ~ let_tx=awaitaxios.get ~ res', res);
        if (!err) {
          return res;
        }
      });
      // console.log('🚀 ~ let_tx=awaitaxios.get ~ _tx', _tx?.data);
      let filterByCollection = _tx?.data?.filter(
        (e) =>
          e?.collection_address?.toLowerCase() === collectionAddr?.toLowerCase()
      );
      // console.log(
      //   '🚀 ~ getTransactions ~ filterByCollection',
      //   filterByCollection.reverse()
      // );
      setTransactions(filterByCollection);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log('🚀 ~ getTransactions ~ error', error);
    }
  };

  return (
    <div className="container">
      <div className="row  d-grid justify-content-center">
        <div className="col-12 col-md-8 d-grid justify-content-center ">
          {loader ? (
            <div className="col pt-5 mt-5 d-flex justify-content-center align-content-lg-center">
              <span class="loader"></span>
            </div>
          ) : (
            <>
              {transactions.length > 0 ? (
                transactions.map((e) => {
                  return (
                    <div
                      className="row d-flex py-3 my-2 rounded shadow-lg"
                      style={{
                        backgroundColor: '#1a456d98'
                      }}
                    >
                      <div className="col-12  col-md-10 text-center text-md-left">
                        <span
                          style={{
                            cursor: 'copy'
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(e?.user_address);
                            toast.success('Copied');
                          }}
                        >
                          {e?.user_address?.substring(0, 10)}...
                          {e?.user_address?.substring(30, 42)}
                        </span>
                      </div>
                      <div className="col-12 col-md-2 col text-center text-md-right pr-5">
                        <span className="text-white">{e?.nfts} NFT</span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <h4 className="text-center">No transactions found!</h4>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

import React, {useEffect, useState} from 'react';
import {useConnectWallet} from '@web3-onboard/react';
import {Link} from 'react-router-dom';
// import {  useSelector } from 'react-redux';
import {Wallet} from '../onboard/onboard';
const Header = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [{wallet}] = useConnectWallet();

  useEffect(() => {
    if (wallet) {
      if (
        wallet?.accounts[0]?.address.toLowerCase() ===
          '0xbba8732ee7c9e61bc05af01006785d0d6cd2471e'.toLowerCase() ||
        wallet?.accounts[0]?.address.toLowerCase() ===
          '0x6bb81ca8ec2f7ccbb08a5b1df8c32781ef3e1c2d'.toLowerCase()
      ) {
        setIsAdmin(true);
      }
    } else {
      setIsAdmin(false);
    }
  }, [wallet]);

  return (
    <>
      <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container ">
          <Link to="https://roo.finance" className="navbar-brand">
            <img src="\img/Roo.png" width={50} alt="Roo Finance" />
            <img src="\logoGia.jpg" width={50} alt="Roo Finance" />
            {/* Roo Finance */}
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon pt-1">
              <i className="icon-menu m-0 text-white" />
            </span>
          </button>
          <div
            class="collapse navbar-collapse  justify-content-center"
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav  mb-2 mb-lg-0 text-center">
              <li class="nav-item px-2">
                <Link to={'/'} class="nav-link ">
                  Home
                </Link>
              </li>
              <li class="nav-item px-2">
                <Link
                  to={'https://roo.finance/projects'}
                  target="_blank"
                  class="nav-link "
                >
                  LaunchPad
                </Link>
              </li>
              <li class="nav-item px-2">
                <p
                  className="nav-link dropdown-toggle mb-0"
                  style={{
                    cursor: 'pointer'
                  }}
                  href="#"
                  id="stakingDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  rel="noreferrer"
                >
                  Staking
                </p>
                <ul className="dropdown-menu" aria-labelledby="stakingDropdown">
                  <li>
                    <Link
                      to="https://Staking.Roo.Finance"
                      className="dropdown-item dropdown-item-black"
                      target="_blank"
                    >
                      V1 Staking
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://v2staking.Roo.Finance"
                      target="_blank"
                      className="dropdown-item dropdown-item-black"
                    >
                      V2 Staking
                    </Link>
                  </li>
                </ul>
              </li>
              <li class="nav-item px-2">
                <Link
                  to={'#'}
                  target="_blank"
                  class="nav-link "
                >
                  Coin Staking
                </Link>
              </li>
              <li class="nav-item px-2">
                <Link
                  to={'/deploy-contract'}
                  class="nav-link "
                >
                  Deploy
                </Link>
              </li>
            </ul>
            
          </div>
          <div className='d-flex justify-content-center'>

          <ul
              className="navbar-nav action "
              // style={{
                //   width: 'max-content'
                // }}
                >
              <Wallet />
            </ul>
              </div>
        </div>
      </nav>
    </>
    
  );
};

export default Header;

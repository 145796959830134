import {ethers} from 'ethers';
import {stakingContractAbi} from './stakingContract';
import {  nftTokenAbi } from './nftContract';
import { tokenAbi } from './token';

// export const stakingContractInst = async (provider) => {
//   const etherProvider = new ethers.providers.Web3Provider(provider);
//   const etherSigner = etherProvider?.getSigner();
//   const _stakingContractInst = new ethers.Contract(
//     stakingContractAddr?.toLowerCase(),
//     stakingContractAbi,
//     etherSigner
//   );
//   return _stakingContractInst;
// };

export const tokenContractInst=async(provider,address)=>{
  const etherProvider = new ethers.providers.Web3Provider(provider);
  const etherSigner = etherProvider?.getSigner();
  const _tokenContractInst = new ethers.Contract(
    address,
    tokenAbi,
    etherSigner
  );
  return _tokenContractInst;
}

export const nftContractInst = async (provider,addr) => {
    const etherProvider = new ethers.providers.Web3Provider(provider);
    const etherSigner = etherProvider?.getSigner();
    const _nftContractInst = new ethers.Contract(
      addr?.toLowerCase(),
      nftTokenAbi,
      etherSigner
    );
    return _nftContractInst;
  };


  export const stakingContractInstByAddr = async (provider,addr) => {
    const etherProvider = new ethers.providers.Web3Provider(provider);
    const etherSigner = etherProvider?.getSigner();
    const _stakingContractInst = new ethers.Contract(
      addr?.toLowerCase(),
      stakingContractAbi,
      etherSigner
    );
    return _stakingContractInst;
  };


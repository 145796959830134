import React, {useEffect, useState} from 'react';
// import Web3 from 'web3';
import {useConnectWallet} from '@web3-onboard/react';
// import axios from 'axios';
import {ethers} from 'ethers';
import {ToastContainer, toast} from 'react-toastify';
import {
  stakingContractInstByAddr,
  tokenContractInst
} from '../../contracts/contractInstance';
import bigInt from 'big-integer';
import axios from 'axios';

export default function Reward({addr, collection}) {
  // console.log('🚀 ~ Reward ~ collection', collection);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [{wallet}] = useConnectWallet();
  const [rewardAmount, setRewardAmount] = useState('');
  const [currentRewardAmount, setCurrentRewardAmount] = useState('');
  const [rewardDuration, setRewardDuration] = useState('');
  const [recoverErcAddr, setRecoverErcAddr] = useState('');
  const [recoverErcAmount, setRecoverErcAmount] = useState('');
  const [withdrawAirNftAddr, setWithdrawAirNftAddr] = useState('');
  const [withdrawAirNftAmount, setWithdrawAirNftAmount] = useState('');
  const [withdrawCollNftId, setWithdrawCollNftId] = useState('');
  const [myLoader, setMyLoader] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [mintUrl, setMintUrl] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [mediumUrl, setMediumUrl] = useState('');
  const [socialUrl, setSocialUrl] = useState('');

  useEffect(() => {
    fetchData();

    return () => {};
  }, []);

  const fetchData = async () => {
    try {
      

    let standardtokenContract = await stakingContractInstByAddr(
      wallet?.provider,
      addr
    );
    let _rewAmt = await standardtokenContract.rewardAmount();
    // console.log('🚀 ~ fetchData ~ _rewAmt', _rewAmt);
  } catch (error) {
      console.log('🚀 ~ fetchData ~ error', error)
      
  }
  };

  const _setRewardAmount = async () => {
    try {
      if (!rewardAmount) {
        toast.error('Enter amount first!');
        return null;
      }
      setIsLoading2(true);
      let standardtokenContract = await stakingContractInstByAddr(
        wallet?.provider,
        addr
      );
      let _tokenContractInst = await tokenContractInst(
        wallet?.provider,
        collection?.token_address
      );
      let _decimal = await _tokenContractInst.decimals();
      // console.log('🚀 ~ const_setRewardAmount= ~ _decimal', _decimal);

      let amount = bigInt(rewardAmount * 10 ** _decimal.toString());
      // console.log('🚀 ~ const_setRewardAmount= ~ amount', amount);
      let _approve = await _tokenContractInst.approve(addr, amount.toString());
      let waitforapprove = await _approve.wait();
      if (waitforapprove) {
        let fee = await standardtokenContract.setRewardAmount(
          amount.toString()
        );
        let waitFortx = await fee.wait();
        if (waitFortx) {
          setIsLoading2(false);
          toast.success('Transaction successful');
        }
      }
    } catch (error) {
      setIsLoading2(false);
      let _stringify = JSON.stringify(error);
      let _parse = JSON.parse(_stringify);
      if (_parse?.error?.data?.message) {
        toast.error(_parse?.error?.data?.message);
      }
      console.log('🚀 ~ const_setRewardAmount= ~ error', error);
    }
  };

  const _setRewardsDuration = async () => {
    try {
      if (!rewardDuration) {
        toast.error('Enter reward duration frist!');
        return null;
      }
      setIsLoading(true);
      let standardtokenContract = await stakingContractInstByAddr(
        wallet?.provider,
        addr
      );

      let fee = await standardtokenContract.setRewardsDuration(rewardDuration);
      let waitFortx = await fee.wait();
      if (waitFortx) {
        setIsLoading(false);
        toast.success('Transaction successful');
      }
    } catch (error) {
      console.log('🚀 ~ const_setRewardsDuration= ~ error', error);
      setIsLoading(false);
      let _stringify = JSON.stringify(error);
      let _parse = JSON.parse(_stringify);
      if (_parse?.error?.data?.message) {
        toast.error(_parse?.error?.data?.message);
      }
    }
  };
  const hanldeRecoverErc20 = async () => {
    try {
      setMyLoader('recoverErc20');

      let standardtokenContract = await stakingContractInstByAddr(
        wallet?.provider,
        addr
      );
      let _amount = bigInt(recoverErcAmount);
      // console.log('🚀 ~ hanldeRecoverErc20 ~ _amount', _amount);
      let _call = await standardtokenContract.recoverERC20(
        recoverErcAddr,
        _amount?.toString()
      );
      let waitFortx = await _call.wait();
      if (waitFortx) {
        toast.success('Transaction Success!');
        setMyLoader('');
      }
    } catch (error) {
      setMyLoader('');

      console.log('🚀 ~ hanldeRecoverErc20 ~ error', error);
      toast.error('Something went wrong!');
    }
  };
  const hanldeWithdrawAirdropNft = async () => {
    try {
      setMyLoader('airdropNft');
      let standardtokenContract = await stakingContractInstByAddr(
        wallet?.provider,
        addr
      );
      let _tokenIds = withdrawAirNftAmount.split(',');
      // console.log('🚀 ~ hanldeWithdrawAirdropNft ~ _tokenIds', _tokenIds);

      let _call = await standardtokenContract.withdrawAirdropedNFT(
        withdrawAirNftAddr,
        _tokenIds
      );
      let waitForTx = await _call.wait();
      if (waitForTx) {
        setMyLoader('');

        toast.success('Transaction successfull!');
      }
    } catch (error) {
      setMyLoader('');

      console.log('🚀 ~ hanldeWithdrawAirdropNft ~ error', error);
      toast.error('Something went wrong!');
    }
  };
  const hanldeWithdrawCollectionNftIfIssue = async () => {
    try {
      setMyLoader('withdrawCollection');
      let standardtokenContract = await stakingContractInstByAddr(
        wallet?.provider,
        addr
      );
      // console.log(
      //   '🚀 ~ hanldeWithdrawCollectionNftIfIssue ~ withdrawCollNftId',
      //   withdrawCollNftId
      // );

      let _tokenIds = withdrawCollNftId.split(',');
      // console.log(
      //   '🚀 ~ hanldeWithdrawCollectionNftIfIssue ~ _tokenIds',
      //   _tokenIds
      // );

      let _call = await standardtokenContract.withdrawCollectionNFTIfFoundIssue(
        _tokenIds
      );
      let waitFortx = await _call.wait();
      if (waitFortx) {
        toast.success('Transaction Successful !');
        setMyLoader('');
      }
    } catch (error) {
      console.log('🚀 ~ hanldeWithdrawCollectionNftIfIssue ~ error', error);
      setMyLoader('');
      toast.error('Something went wrong!');
    }
  };

  const handleProfilePicture = async () => {
    try {
      setMyLoader('picture');
      let data = {
        _id: collection._id,
        picture: imageUrl
      };
      let url = `${process.env.REACT_APP_API_URL}nfts`;
      let _updateImage = await axios.put(url, data).then((res, err) => {
        if (!err) {
          setMyLoader('');
          toast.success('Profile Update successfully');
          return res;
        }
        setMyLoader('');
      });
      // console.log(
      //   '🚀 ~ let_updateImage=awaitaxios.put ~ _updateImage',
      //   _updateImage
      // );

      setMyLoader('');
    } catch (error) {
      console.log('🚀 ~ handleProfilePicture ~ error', error);
      setMyLoader('');
    }
  };

  const handleMintUrl = async () => {
    if (!mintUrl) {
      return toast.error('Empty field');
    }
    try {
      setMyLoader('mintUrl');
      let data = {
        _id: collection._id,
        mintUrl: mintUrl
      };
      let url = `${process.env.REACT_APP_API_URL}nfts`;
      let _updateImage = await axios.put(url, data).then((res, err) => {
        if (!err) {
          setMyLoader('');
          toast.success('URL Update successfully');
          return res;
        }
        setMyLoader('');
      });
      // console.log(
      //   '🚀 ~ let_updateImage=awaitaxios.put ~ _updateImage',
      //   _updateImage
      // );

      setMyLoader('');
    } catch (error) {
      console.log('🚀 ~ handleProfilePicture ~ error', error);
      setMyLoader('');
    }
  };
  const handleWebsiteUrl = async () => {
    if (!websiteUrl) {
      return toast.error('Empty field');
    }
    try {
      setMyLoader('websiteUrl');
      let data = {
        _id: collection._id,
        websiteUrl: websiteUrl
      };
      let url = `${process.env.REACT_APP_API_URL}nfts`;
      let _updateImage = await axios.put(url, data).then((res, err) => {
        if (!err) {
          setMyLoader('');
          toast.success('URL Update successfully');
          return res;
        }
        setMyLoader('');
      });
      // console.log(
      //   '🚀 ~ let_updateImage=awaitaxios.put ~ _updateImage',
      //   _updateImage
      // );

      setMyLoader('');
    } catch (error) {
      console.log('🚀 ~ handleProfilePicture ~ error', error);
      setMyLoader('');
    }
  };
  const handleMediumUrl = async () => {
    if (!mediumUrl) {
      return toast.error('Empty field');
    }
    try {
      setMyLoader('mediumUrl');
      let data = {
        _id: collection._id,
        mediumUrl: mediumUrl
      };
      let url = `${process.env.REACT_APP_API_URL}nfts`;
      let _updateImage = await axios.put(url, data).then((res, err) => {
        if (!err) {
          setMyLoader('');
          toast.success('URL Update successfully');
          return res;
        }
        setMyLoader('');
      });
      // console.log(
      //   '🚀 ~ let_updateImage=awaitaxios.put ~ _updateImage',
      //   _updateImage
      // );

      setMyLoader('');
    } catch (error) {
      console.log('🚀 ~ handleProfilePicture ~ error', error);
      setMyLoader('');
    }
  };
  const handleSocialUrl = async () => {
    if (!socialUrl) {
      return toast.error('Empty field');
    }
    try {
      setMyLoader('socialUrl');
      let data = {
        _id: collection._id,
        socialUrl: socialUrl
      };
      let url = `${process.env.REACT_APP_API_URL}nfts`;
      let _updateImage = await axios.put(url, data).then((res, err) => {
        if (!err) {
          setMyLoader('');
          toast.success('URL Update successfully');
          return res;
        }
        setMyLoader('');
      });
      // console.log(
      //   '🚀 ~ let_updateImage=awaitaxios.put ~ _updateImage',
      //   _updateImage
      // );

      setMyLoader('');
    } catch (error) {
      console.log('🚀 ~ handleProfilePicture ~ error', error);
      setMyLoader('');
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container">
        <div className="row mb-3 ">
          <div className="col-6">
            <h5 className="">Set Reward duration</h5>
            <div className="form-group">
              <label htmlFor="name">Duration</label>
              <input
                type="text"
                id="rduration"
                name="rduration"
                placeholder="Enter time in epoch format"
                required="required"
                value={rewardDuration}
                onChange={(e) => setRewardDuration(e.target.value)}
              />
            </div>
            <div className="col-12 ">
              {isLoading ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden"></span>
                </div>
              ) : (
                <button
                  className="btn btn-primary active"
                  onClick={_setRewardsDuration}
                >
                  submit
                </button>
              )}
            </div>
          </div>
          <div className="col-6">
            <h5 className="">Set Reward Amount</h5>
            <div className="form-group">
              <label htmlFor="name">Amount</label>
              <input
                type="text"
                id="ramount"
                name="ramount"
                placeholder="Enter reward amount"
                required="required"
                value={rewardAmount}
                onChange={(e) => setRewardAmount(e.target.value)}
              />
            </div>
            <div className="col-12 ">
              {isLoading2 ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden"></span>
                </div>
              ) : (
                <button
                  className="btn btn-primary active"
                  onClick={_setRewardAmount}
                >
                  submit
                </button>
              )}
            </div>
          </div>
          <div className="col-6 pt-2">
            <h5 className="">Update NFT Profile Picture </h5>
            <div className="form-group">
              <label htmlFor="name">Image URL</label>
              <input
                type="text"
                id="ramount"
                name="ramount"
                placeholder="Enter Image URL"
                required="required"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </div>
            <div className="col-12 ">
              {myLoader === 'picture' ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden"></span>
                </div>
              ) : (
                <button
                  className="btn btn-primary active"
                  onClick={handleProfilePicture}
                >
                  submit
                </button>
              )}
            </div>
          </div>
          <div className="col-6 pt-2">
            <h5 className="">Update Mint URL</h5>
            <div className="form-group">
              <label htmlFor="name">Mint URL</label>
              <input
                type="text"
                id="ramount"
                name="ramount"
                placeholder="Enter Mint URL"
                required="required"
                value={mintUrl}
                onChange={(e) => setMintUrl(e.target.value)}
              />
            </div>
            <div className="col-12 ">
              {myLoader === 'mintUrl' ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden"></span>
                </div>
              ) : (
                <button
                  className="btn btn-primary active"
                  onClick={handleMintUrl}
                >
                  submit
                </button>
              )}
            </div>
          </div>
          <div className="col-6">
            <h5 className="">Set Website URL</h5>
            <div className="form-group">
              <label htmlFor="name">Website URL</label>
              <input
                type="text"
                id="ramount"
                name="ramount"
                placeholder="Enter website url"
                required="required"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
              />
            </div>
            <div className="col-12 ">
              {myLoader === 'websiteUrl' ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden"></span>
                </div>
              ) : (
                <button
                  className="btn btn-primary active"
                  onClick={handleWebsiteUrl}
                >
                  submit
                </button>
              )}
            </div>
          </div>
          <div className="col-6">
            <h5 className="">Set Medium URL</h5>
            <div className="form-group">
              <label htmlFor="name">Medium URL</label>
              <input
                type="text"
                id="ramount"
                name="ramount"
                placeholder="Enter medium url"
                required="required"
                value={mediumUrl}
                onChange={(e) => setMediumUrl(e.target.value)}
              />
            </div>
            <div className="col-12 ">
              {myLoader === 'mediumUrl' ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden"></span>
                </div>
              ) : (
                <button
                  className="btn btn-primary active"
                  onClick={handleMediumUrl}
                >
                  submit
                </button>
              )}
            </div>
          </div>
          <div className="col-6">
            <h5 className="">Set Social URL</h5>
            <div className="form-group">
              <label htmlFor="name">Social URL</label>
              <input
                type="text"
                id="ramount"
                name="ramount"
                placeholder="Enter social url"
                required="required"
                value={socialUrl}
                onChange={(e) => setSocialUrl(e.target.value)}
              />
            </div>
            <div className="col-12 ">
              {myLoader === 'socialUrl' ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden"></span>
                </div>
              ) : (
                <button
                  className="btn btn-primary active"
                  onClick={handleSocialUrl}
                >
                  submit
                </button>
              )}
            </div>
          </div>
        </div>

        {wallet?.accounts[0].address?.toLowerCase() ===
          process.env.REACT_APP_PLATFORM_OWNER ||
        wallet?.accounts[0].address?.toLowerCase() ===
          '0x892607bEF2b3c5d70917ee57FD1Ae55f38A63873'.toLowerCase() ? (
          <>
            <div className="row">
              <div className="col-6">
                <h5 className="">Recover ERC20</h5>
                <div className="form-group">
                  <label htmlFor="name">Token Address</label>
                  <input
                    type="text"
                    id="ramount"
                    name="ramount"
                    placeholder="Enter Token Address"
                    required="required"
                    value={recoverErcAddr}
                    onChange={(e) => setRecoverErcAddr(e.target.value)}
                    className="my-2"
                  />
                  <label htmlFor="name">Amount</label>
                  <input
                    type="text"
                    id="ramount"
                    name="ramount"
                    placeholder="Enter reward amount with decimal"
                    required="required"
                    value={recoverErcAmount}
                    className="my-2"
                    onChange={(e) => setRecoverErcAmount(e.target.value)}
                  />
                </div>
                <div className="col-12 ">
                  {myLoader === 'recoverErc20' ? (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  ) : (
                    <button
                      className="btn btn-primary active"
                      onClick={hanldeRecoverErc20}
                    >
                      submit
                    </button>
                  )}
                </div>
              </div>
              <div className="col-6">
                <h5 className="">Withdraw Airdroped NFT</h5>
                <div className="form-group">
                  <label htmlFor="name"> Address</label>
                  <input
                    type="text"
                    id="ramount"
                    name="ramount"
                    placeholder="Enter NFT Contract Address"
                    required="required"
                    value={withdrawAirNftAddr}
                    className="my-2"
                    onChange={(e) => setWithdrawAirNftAddr(e.target.value)}
                  />
                  <label htmlFor="name">Token ID</label>
                  <input
                    type="text"
                    id="ramount"
                    name="ramount"
                    placeholder="Enter token id like this 2,6,3"
                    required="required"
                    value={withdrawAirNftAmount}
                    onChange={(e) => setWithdrawAirNftAmount(e.target.value)}
                    className="my-2"
                  />
                </div>
                <div className="col-12 ">
                  {myLoader === 'airdropNft' ? (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  ) : (
                    <button
                      className="btn btn-primary active"
                      onClick={hanldeWithdrawAirdropNft}
                    >
                      submit
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <h5 className="">Withdraw Collection NFT (If Issue) </h5>
                <div className="form-group">
                  <label htmlFor="name"> Token ID</label>
                  <input
                    type="text"
                    id="ramount"
                    name="ramount"
                    placeholder="Enter token id like this 5,1,9"
                    required="required"
                    value={withdrawCollNftId}
                    onChange={(e) => setWithdrawCollNftId(e.target.value)}
                  />
                </div>
                <div className="col-12 ">
                  {myLoader === 'withdrawCollection' ? (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  ) : (
                    <button
                      className="btn btn-primary active"
                      onClick={hanldeWithdrawCollectionNftIfIssue}
                    >
                      submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
}

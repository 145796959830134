import React, {useEffect, useState} from 'react';
import Moralis from 'moralis';
import {useConnectWallet} from '@web3-onboard/react';
import NftCard from './NftCard';
import './stake.css';
import {
  nftContractInst,
  stakingContractInst,
  stakingContractInstByAddr
} from '../../../contracts/contractInstance';
import {ToastContainer, toast} from 'react-toastify';
import axios from 'axios';
export default function Stake({stakingContractAddr, collectionAddr,setCallfunction,callFunction}) {
  const [{wallet}] = useConnectWallet();
  const [stakeNft, setStakeNft] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNfts, setSelectedNfts] = useState([]);
  const [nftLoader, setNftLoader] = useState(false);

  const collectionAddress = collectionAddr;
  useEffect(() => {
    if (wallet) {
      getStakeNfts();
    }
  }, [wallet?.accounts[0].address]);

  const getStakeNfts = async () => {
    try {
      setNftLoader(true);
      const response = await Moralis.EvmApi.nft.getWalletNFTs({
        address: wallet?.accounts[0]?.address.toLowerCase(),
        chain: '0x19', //cronos 0x19 //sepolia 0xaa36a7
        format: 'decimal',
        tokenAddresses: [collectionAddr],
        mediaItems: false
      });

      let sortNft = response?.raw?.result.sort(
        (e, i) => e?.token_id - i.token_id
      );
      setStakeNft(sortNft);
      setNftLoader(false);
    } catch (error) {
      setNftLoader(false);
      console.log('🚀 ~ getStakeNfts ~ error', error);
    }
  };

  const handleStake = async () => {
    // local
    let params;
    let stakeUrl = `${process.env.REACT_APP_API_URL}stake`;
    let stakeTransactionUrl = `${process.env.REACT_APP_API_URL}transactions`;
    // console.log('🚀 ~ handleStake ~ selectedNfts', selectedNfts)
    let _txObj = {
      user_address: wallet?.accounts[0].address,
      nfts: selectedNfts.length,
      collection_address:collectionAddr
    };
    try {
      setIsLoading(true);
      const _stakingContractInst = await stakingContractInstByAddr(
        wallet?.provider,
        stakingContractAddr
      );
      const _nftContractInst = await nftContractInst(
        wallet?.provider,
        collectionAddr
      );
      const _checkIsApprovedForAll = await _nftContractInst.isApprovedForAll(
        wallet?.accounts[0].address,
        stakingContractAddr
      );

      if (!_checkIsApprovedForAll) {
        let _approveForAll = await _nftContractInst.setApprovalForAll(
          stakingContractAddr,
          true
        );
        let waitForApprove = await _approveForAll.wait();
        if (waitForApprove) {
          params = selectedNfts;
          let _stake = await _stakingContractInst.stake(params);
          let _waitForStake = await _stake.wait();
          if (_waitForStake) {
            let obj = {
              user_address: wallet?.accounts[0].address,
              collections: [
                {
                  collection_address: collectionAddr,
                  stakingContractAddr: stakingContractAddr,
                  token_id: params
                }
              ]
            };

            let _data = await axios.post(stakeUrl, obj).then((res, err) => {
              if (!err) {
                return res;
              }
            });
            let _tx = await axios
              .post(stakeTransactionUrl, _txObj)
              .then((res, err) => {
                if (!err) {
                  return res;
                }
              });
            setSelectedNfts([]);
            getStakeNfts();
            setCallfunction(!callFunction)
            toast.success('Stake Successfull');
            setIsLoading(false);
          }
        }
      } else {
        params = selectedNfts;
        let _stake = await _stakingContractInst.stake(params);
        let _waitForStake = await _stake.wait();
        if (_waitForStake) {
          let obj = {
            user_address: wallet?.accounts[0].address,
            collections: [
              {
                collection_address: collectionAddr,
                stakingContractAddr: stakingContractAddr,
                token_id: params
              }
            ]
          };


          let _data = await axios.post(stakeUrl, obj).then((res, err) => {
            if (!err) {
              return res;
            }
          });
          let _tx = await axios
          .post(stakeTransactionUrl, _txObj)
          .then((res, err) => {
            if (!err) {
              return res;
            }
          });
          setSelectedNfts([]);
          getStakeNfts();
          setCallfunction(!callFunction)

          toast.success('Stake Successfull');
          setIsLoading(false);
        }
      }
    } catch (error) {
      let _string = JSON.stringify(error);
      let _parse = JSON.parse(_string);
      if (_parse?.reason) {
        toast.error(_parse?.reason);
      } else {
        toast.error('Something went wrong!');
      }

      setIsLoading(false);
    }
  };

  const cardSelectHandle = (tokenId) => {
    if (!selectedNfts.includes(tokenId)) {
      setSelectedNfts([...selectedNfts, tokenId]);
    } else {
      const filter = selectedNfts.filter((id) => {
        return id != tokenId;
      });
      setSelectedNfts(filter);
    }
  };
  return (
    <>
      {/* <button onClick={check}>click</button> */}
      <ToastContainer />
      <div className="container myStakeContainer">
        <div className="row d-flex justify-content-center">
          {nftLoader ? (
            <div className="col   d-flex justify-content-center align-content-lg-center">
              <span class="loader"></span>
            </div>
          ) : (
            <>
              {stakeNft && stakeNft?.length > 0 ? (
                stakeNft?.map((item) => {
                  return (
                    <div className="col-3 myColDiv px-md-0 px-4  pb-3">
                      <NftCard
                        item={item}
                        selectedNfts={selectedNfts}
                        cardSelectHandle={cardSelectHandle}
                      />
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="col">
                    <p className="text-center myShadowText">
                      {' '}
                      NFT's Not Found!{' '}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex justify-content-center">
          <button
            onClick={handleStake}
            disabled={isLoading}
            className="StakeButton mt-md-3"
          >
            {isLoading ? (
              <div class="spinner-border text-info" role="status"></div>
            ) : (
              'Stake'
            )}
          </button>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {ChainId, DAppProvider} from '@usedapp/core';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import {ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client';
import {Provider} from 'react-redux';
import {store} from './redux/store';

import 'bootstrap/dist/js/bootstrap.js';
import "react-toastify/dist/ReactToastify.css";

const config = {
  pollingInterval: 5000,
  autoConnect: true,
  readOnlyChainId:
    process.env.REACT_APP_ENVIRONMENT === 'DEV'
      ? ChainId.CronosTestnet
      : ChainId.Cronos,
  readOnlyUrls: {
    [process.env.REACT_APP_ENVIRONMENT === 'DEV'
      ? ChainId.CronosTestnet
      : ChainId.Cronos]:
      process.env.REACT_APP_ENVIRONMENT === 'DEV'
        ? process.env.REACT_APP_CRONOS_TESTNET_RPC
        : process.env.REACT_APP_CRONOS_RPC
  }
};

const client = new ApolloClient({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
  cache: new InMemoryCache()
});

const root = createRoot(document.getElementById('root'));
root.render(
  <>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <DAppProvider config={config}>
            <App />
          </DAppProvider>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

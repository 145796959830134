import React, {useEffect, useState} from 'react';
import Web3 from 'web3';
import {useNavigate, useParams} from 'react-router';
import {useDropzone} from 'react-dropzone';
import {useSelector} from 'react-redux';
import {useConnectWallet} from '@web3-onboard/react';
import axios from 'axios';
import {ethers} from 'ethers';
import {ToastContainer, toast} from 'react-toastify';
import {stakingContractAbi} from '../../contracts/stakingContract';
import {convertBase64} from '../common/utils';
import {stakingByteCode} from '../../contracts/token';
import bigInt from 'big-integer';

const DragAndDrop = ({formData, setFormData}) => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const imageDropHandler = async (file) => {
    const base64 = await convertBase64(file);
    setPreviewImage(file);
    setFormData({...formData, image: base64});
  };

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': []
    },
    maxSize: 500000,
    maxFiles: 1,
    onDrop: async (files) => {
      const file = files[0];

      imageDropHandler(file);
    },
    onDropRejected: (abc) => {
      // console.log(abc[0].errors[0].message);
      setErrorMsg(abc[0].errors[0].message);
    }
  });

  return (
    <div className="drag_drog admin_form_dropzone_wrapper">
      {!formData?.image ? (
        <div {...getRootProps({className: 'dropzone admin_form_dropzone'})}>
          <input {...getInputProps()} name="avatar_url" type="file" />
          <p className="admin_dropzone">
            Drag 'n' drop some files here,
            <br />
            or click to select files
            <br />
            <i className="fas fa-plus"></i>
          </p>

          {errorMsg && (
            <p
              style={{
                marginTop: '50px',
                color: '#ff0000 !important',
                fontWeight: '600'
              }}
            >
              {errorMsg}
            </p>
          )}
        </div>
      ) : (
        <>
          <div className="upload_img_wrapper">
            <span>
              <img
                className="uploaded_image"
                src={previewImage && URL.createObjectURL(previewImage)}
                height={100}
                style={{borderRadius: '10px'}}
              />{' '}
              <span
                onClick={() => {
                  setFormData({...formData, image: ''});
                  setErrorMsg(null);
                }}
              >
                <i className="fas fa-plus"></i>
              </span>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

const Admin = () => {
  const params = useParams();
  // console.log('🚀 ~ Admin ~ params', params?.address);
  const [formData, setFormData] = useState({});
  const [newContract, setNewContract] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [{wallet, connecting}, connect, disconnect] = useConnectWallet();
  const userData = useSelector((state) => state?.state);

  // useEffect(() => {
  //   handleContract();
  // }, [newContract]);
  const handleContract = async (newContractAddr) => {
    try {
      if (newContractAddr) {
        let obj = {
          name: formData.name,

          description: formData.description,
          contract_address: newContractAddr,
          creator_address: wallet?.accounts[0]?.address,
          picture:
            'https://www.dunstableroadrunners.org/wp-content/uploads/2019/04/image-coming-soon.jpg', //default image
          token_address: formData.ERC20Address,
          collection_address: formData.collectionAddress
        };

        let url = `${process.env.REACT_APP_API_URL}nfts`;
        let _createCollection = await axios
          .post(url, obj)
          .then(async (res, err) => {
            if (err) {
              console.log(
                '🚀 ~ let_createCollection=awaitaxios.post ~ err',
                err
              );
              toast.error('Something went wrong!');
              setIsLoading(false);

              return err;
            } else {
              return res;
            }
          });

        let _res = await _createCollection;
        if (_res) {
          toast.success('Transaction successful!');
          setIsLoading(false);
          setTimeout(() => {
            navigate('/');
          }, 3000);
        }
      }
    } catch (error) {
      setIsLoading(false);

      console.log('🚀 ~ handleContract ~ error', error);
    }
  };

  const submitHandler = async (e) => {
    if (!wallet?.accounts[0]?.address) {
      return toast.error('Please connect wallet first!');
    }
    e.preventDefault();
    const etherProvider = () => {
      try {
        return new ethers.providers.Web3Provider(wallet?.provider);
      } catch (error) {
        setIsLoading(false);
        toast.error('Something went wrong!');
        console.log('🚀 ~ StakingPool ~ error', error);
      }
    };
    const etherSigner = etherProvider()?.getSigner();
    const factory = new ethers.ContractFactory(
      stakingContractAbi,
      '0x' + stakingByteCode,
      etherSigner
    );

    let _arguments = [formData.ERC20Address, formData.collectionAddress];
    // let _val = bigInt(200 * 10 ** 18);
    setIsLoading(true);
    const contract = await factory
      // .deploy(..._arguments, {value: _val.toString()})
      .deploy(..._arguments)

      .then(function (newContractInstance) {
        if (!newContractInstance?.address) {
          setIsLoading(true);

          return;
        }

        setNewContract(newContractInstance?.address);
        handleContract(newContractInstance?.address);
      })
      .catch(function (error) {
        let par = JSON.stringify(error);
        let _par = JSON.parse(par);
        if (_par?.data?.message) {
          toast.error(_par?.data?.message);
          setIsLoading(false);

        } else {
          console.log('🚀 ~ submitHandler ~ _par', _par);
          console.log('🚀 ~ error', error);

          toast.error('Something went wrong!');
          setIsLoading(false);
        }
      });
  };

  const inputChangeHandler = ({target: {name, value}}) => {
    formData[name] = value.trim();
    let newObj = {...formData};
    setFormData(newObj);
  };

  return (
    // <>
    //   <section className='apply-area contact'>

    //   </section>
    // </>

    <section>
      <ToastContainer />
      <div className="container-fluid section">
        <div className="row ">
          {/* menu section start */}

          <div className="col-lg-12">
            <div className="container">
              <div className="row stack_row_box">
                <div className="container">
                  <div className="row d-flex justify-content-center">
                    <div className="col-12 col-lg-7">
                      <div className="apply-form  no-hover border">
                        {/* Intro */}
                        <div className="intro d-flex justify-content-between align-items-end mb-4">
                          <div className="intro-content d-flex justify-content-between w-100">
                            <h3 className="mt-3 mb-0">Admin</h3>{' '}
                            <a
                              href="/pdf/deploy.pdf"
                              target="_blank"
                              className="pt-4 deployUrl"
                            >
                              How to deploy
                            </a>
                          </div>
                        </div>
                        <form id="contact-form">
                          <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="boredape"
                              required="required"
                              onChange={inputChangeHandler}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <textarea
                              id="description"
                              name="description"
                              placeholder="Description"
                              cols={30}
                              rows={3}
                              required="required"
                              defaultValue={''}
                              onChange={inputChangeHandler}
                            />
                            <small className="form-text mt-2">
                              Briefly describe what you need
                            </small>
                          </div>

                          <div className="form-group">
                            <label htmlFor="ERC20Address">
                              Reward Token Address
                            </label>
                            <input
                              type="text"
                              id="ERC20Address"
                              name="ERC20Address"
                              placeholder="ERC20 Token Address"
                              required="required"
                              onChange={inputChangeHandler}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="collectionaddress">
                              NFT Collection Address
                            </label>
                            <input
                              type="text"
                              id="collectionAddress"
                              name="collectionAddress"
                              placeholder="NFT Collection Address"
                              required="required"
                              onChange={inputChangeHandler}
                            />
                          </div>
                          {/* <div className="form-group">
                            <label htmlFor="name">
                              Profile Picture{' '}
                              <span className='pl-2 myCursor'  title="https://drive.google.com/file/d/18Xkh_ZSbeweIykK_DqRK6Ib2y_DwwuYD/view?usp=sharing">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="white"
                                  class="mr-2 bi bi-info-circle-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                </svg>
                              </span>{' '}
                            </label>
                            <input
                              type="text"
                              id="picture"
                              name="picture"
                              placeholder="Enter google drive picture link"
                              required="required"
                              onChange={inputChangeHandler}
                            />
                            <small style={{fontSize: '13px'}} className="pl-1">
                              Please use approximate 259 * 194 pixels
                            </small>
                          </div> */}

                          {/* <div className="form-group">
                            <label htmlFor="picture">Profile Picture</label>
                            <DragAndDrop
                              formData={formData}
                              setFormData={setFormData}
                            />
                          </div> */}

                          {/* <div className="row pb-3">
                            <div className="col">
                              <p>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="white"
                                  class="mr-2 bi bi-info-circle-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                </svg>
                                Deployment fee{' '}
                                <b className="text-white"> 200 CRO</b> + Gas
                                fees
                              </p>
                            </div>
                          </div> */}
                          <button
                            type="submit"
                            className="btn btn-bordered active"
                            onClick={submitHandler}
                            disabled={
                              !formData.name ||
                              !formData.description ||
                              !formData.ERC20Address ||
                              !formData.collectionAddress
                            }
                          >
                            {isLoading ? (
                              <div className="col-12 text-center">
                                <div className="spinner-border" role="status">
                                  <span className="visually-hidden"></span>
                                </div>
                              </div>
                            ) : (
                              'submit'
                            )}
                          </button>
                        </form>
                        {/* <p className="form-message" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Admin;

import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Staking from '../Staking/Staking';
import Admin from '../Admin/Admin';
import NftDetails from '../Staking/NftDetails';
import UpdateContract from '../Admin/UpdateContract';

const MyRoutes = () => {
  return (
    <div>
      <Routes>
      <Route path='/'>
          <Route index element={<Staking />} />
          <Route path=':address' element={<NftDetails />} />
        </Route>
        {/* <Route exact path="/" element={<Staking />} />
        <Route exact path=":address" element={<NftDetails />} /> */}
        <Route exact path="/deploy-contract" element={<Admin />} />
        <Route exact path="/update-contract/:addr" element={<UpdateContract />} />

        {/* <Route path='/'>
          <Route index element={<StakingTwo />} />
          <Route path=':id' element={<StakingNFT />} />
        </Route>
        <Route exact path='/admin' element={<Admin />} />
        <Route exact path='/admin/:address' element={<Admin />} /> */}
      </Routes>
    </div>
  );
};
export default MyRoutes;

import React, {useEffect, useState} from 'react';
import Stake from './common/Stake';
import Claim from './common/Claim';
import Unstake from './common/Unstake';
import Countdown from 'react-countdown';

import './nftDetails.css';
import {useConnectWallet} from '@web3-onboard/react';
import {useParams, Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  stakingContractInstByAddr,
  tokenContractInst
} from '../../contracts/contractInstance';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import {validUser} from '../common/utils';
import Transactions from './common/Transactions';
import {saveCollection} from '../../redux/features/nftSlice';
export default function NftDetails() {
  const [{wallet}] = useConnectWallet();
  const params = useParams();
  console.log('🚀 ~ NftDetails ~ params', params?.address);
  const {
    nft: {collection}
  } = useSelector((state) => state);
  console.log('🚀 ~ NftDetails ~ collection', collection);
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('stake');
  const [totalNftStaked, setTotalNftStaked] = useState('0');
  const [myNftStaked, setMyNftStaked] = useState('');
  const [currentCollection, setCurrentCollection] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [duration, setDuration] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rewardsPerWeek, setRewardsPerWeek] = useState(0);
  const [decimal, setDecimal] = useState(0);
  const [symbol, setSymbol] = useState('');
  const [admin, setAdmin] = useState(false);
  const [rewardDuration, setRewardDuration] = useState(null);
  const [rewardAmount, setRewardAmount] = useState(null);
  const [callFunction, setCallfunction] = useState(false);

  useEffect(() => {
    if (collection?.length > 0) {
      // console.log('🚀 ~ useEffect ~ collection', collection);
      // console.log(' collection -----');

      let filterData = collection.filter(
        (e) =>
          e.contract_address?.toLowerCase() == params?.address?.toLowerCase()
      );
      // console.log('🚀 ~ useEffect ~ filterData[0]', filterData[0]);
      setCurrentCollection(filterData[0]);
      handleAdmin(filterData[0]);
      if (filterData[0]) {
        getTotalNftStaked(filterData[0]);
        getDecimalSymbol(filterData[0]);
      }
    } else {
      console.log('no collection');
      handleGetNfts();
    }
    return () => {};
  }, [collection, callFunction, wallet?.accounts[0].address]);
  const handleGetNfts = async () => {
    // console.log('handle function call');
    try {
      // setIsLoading(true);
      let url = `${process.env.REACT_APP_API_URL}nfts`;
      let _nfts = await axios.get(url).then((res, err) => {
        if (err) {
          console.log('🚀 ~ let_nfts=awaitaxios.get ~ err', err);
        } else {
          return res;
        }
      });
      if (_nfts) {
        // setIsLoading(false);
        // setAllNfts(_nfts.data);
        // setApiData(_nfts?.data);
        // getTotalNftStaked(_nfts.data);
        dispatch(saveCollection(_nfts?.data));
      }
    } catch (error) {
      // setIsLoading(false);

      console.log('🚀 ~ handleGetNfts ~ error', error);
    }
  };

  const getTotalNftStaked = async (addr) => {
    try {
      const contractByaddr = await stakingContractInstByAddr(
        wallet?.provider,
        addr?.contract_address
      );
      const count = await contractByaddr.totalStaked(addr.collection_address);
      const myNftStaked = await contractByaddr.balanceOf(
        addr.collection_address,
        wallet.accounts[0].address
      );
      setMyNftStaked(myNftStaked.toString());
      // console.log(
      //   '🚀 ~ getTotalNftStaked ~ myNftStaked',
      //   myNftStaked.toString()
      // );
      // let myStake = await contractByaddr.getUserStakedNftId(
      //   addr.collection_address
      // );
      // console.log('🚀 ~ getTotalNftStaked ~ myStake', myStake.toString())
      setTotalNftStaked(count?.toString());
    } catch (error) {
      console.log('🚀 ~ getTotalNftStaked ~ error', error);
    }
  };

  const getPoolRate = async (addr) => {
    try {
      let stakingContract = await stakingContractInstByAddr(
        wallet?.provider,
        addr?.contract_address
      );
      let _rewardAmount = await stakingContract.rewardAmount();

      setRewardAmount(_rewardAmount);
      let _rewardDuration = await stakingContract.rewardsDuration();
      setRewardDuration(_rewardDuration);
      // console.log(
      //   '🚀 ~ getPoolRate ~ _rewardDuration',
      //   _rewardDuration?.toString()
      // );
      setRewardsPerWeek(
        parseInt(_rewardAmount) / parseInt(_rewardDuration) / 604800
      );
      // console.log(
      //   'chekc reeeee',
      //   parseInt(_rewardAmount) / parseInt(_rewardDuration) / 604800
      // );
    } catch (error) {
      console.log('🚀 ~ getPoolRate ~ error', error);
    }
  };

  useEffect(() => {
    setRewardsPerWeek(
      parseInt(rewardAmount) / (parseInt(rewardDuration) / 604800)
    );
  }, [rewardDuration]);

  const getDecimalSymbol = async (addr) => {
    try {
      let stakeContract = await stakingContractInstByAddr(
        wallet?.provider,
        addr?.contract_address
      );
      let reward = await stakeContract.rewardsToken();

      if (reward) {
        let tokenContractIn = await tokenContractInst(wallet?.provider, reward);
        let symbol = await tokenContractIn.symbol();
        let decimals = await tokenContractIn.decimals();

        setSymbol(symbol);
        setDecimal(decimals);
        getPoolRate(addr);
      }
    } catch (error) {
      console.log('🚀 ~ handleRewardsToken ~ error', error);
    }
  };
  const dateInputHandler = (e) => {
    setDuration(parseInt(new Date().getTime() + e.target.value * 1000));
  };

  const rewardsDurationHandler = async () => {
    try {
      // console.log(
      //   '🚀 ~ rewardsDurationHandler ~ currentCollection._id',
      //   currentCollection._id
      // );
      setBtnLoading(true);
      let data = {
        _id: currentCollection._id,
        expire_date: `${duration}`
      };
      let url = `${process.env.REACT_APP_API_URL}nfts`;
      // console.log('🚀 ~ rewardsDurationHandler ~ url', url);
      let _addDate = axios.put(url, data).then((res, err) => {
        // console.log('🚀 ~ let_addDate=axios.put ~ res', res);
        if (err) {
          console.log('🚀 ~ let_addDate=axios.put ~ err', err);
          setLoading(false);
          throw err;
        }
        setBtnLoading(false);

        // navigate('/');
        toast.success('Update done');
        return res;
      });
    } catch (error) {
      console.log('🚀 ~ rewardsDurationHandler ~ error', error);
      toast.error('Something went wrong');
    }
  };

  const renderer = ({days, hours, minutes, seconds, completed}) => {
    if (completed) {
      // setIsCompleted(true);
      return (
        <span className="complete_countdown_text">Countdown complete!</span>
      );
    } else {
      return (
        <span className="time_part">
          {days}d {hours}h:{minutes}m:{seconds}s
        </span>
      );
    }
  };

  const extractImageId = (addr) => {
    let url = addr.split('/')[5];
    return url;
  };

  const handleAdmin = (currentCollection) => {
    // console.log('collextion', currentCollection);
    let _user = validUser(
      wallet?.accounts[0].address,
      currentCollection?.creator_address
    );
    // console.log('🚀 ~collextion handleAdmin ~ _user', _user)
    setAdmin(_user);
  };

  return (
    <section>
      {console.log('jsxxxx------')}
      <ToastContainer />
      <h1 className="text-center">NFT Details</h1>
      <div className="row">
        <div className="col d-flex justify-content-center">
          {admin ? (
            <Link
              to={`/update-contract/${currentCollection?.contract_address}`}
              state={currentCollection}
              className="nftdetailsBtn"
              style={{
                width: 'max-content'
              }}
            >
              Update Contract
            </Link>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="container-fluid pl-3">
        <div className="row  py-5">
          <div className="col-12 col-md-3 d-flex justify-content-center align-items-center py-md-5 py-1">
            <div className=" d-flex justify-content-center myNftDetailsImg">
              <img
                src={
                  currentCollection?.picture
                    ? `https://drive.google.com/thumbnail?id=${extractImageId(
                        currentCollection?.picture
                      )}`
                    : '/img/placeholder.png'
                }
                alt="img"
              />{' '}
            </div>
          </div>
          <div className="col-12 px-0 pl-sm-4 col-md-9 align-items-center justify-content-center  ">
            <div className="row">
              <div className="col-12 col-md-6 ">
                <h3 className="text-center text-md-left  ">
                  {currentCollection?.name}
                </h3>
              </div>
              <div className="col-12 col-md-6 d-grid justify-content-center justify-content-md-end text-center text-md-right  align-items-center pt-4">
                {currentCollection?.websiteUrl ? (
                  <button className="clientSocial h-auto">
                    <a
                      href={`${currentCollection?.websiteUrl}`}
                      target="_blank"
                    >
                      <img
                        src="/img/website.png"
                        className="text-white"
                        alt="www"
                        width={25}
                        height={25}
                      />
                    </a>
                  </button>
                ) : (
                  ''
                )}
                {currentCollection?.mediumUrl ? (
                  <button className="clientSocial">
                    <a href={`${currentCollection?.mediumUrl}`} target="_blank">
                      <img
                        src="/img/medium.png"
                        className="text-white"
                        alt="www"
                        width={25}
                        height={25}
                      />
                    </a>
                  </button>
                ) : (
                  ''
                )}
                {currentCollection?.socialUrl ? (
                  <button className="clientSocial">
                    <a href={`${currentCollection?.socialUrl}`} target="_blank">
                      <img
                        src="/img/social.png"
                        className="text-white"
                        alt="www"
                        width={25}
                        height={25}
                      />
                    </a>
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
            <p className="text-center text-md-left">
              NFT Collection Address :{' '}
              <span className="text-white">
                {currentCollection?.collection_address}
              </span>
              {currentCollection?.mintUrl ? (
                <a
                  href={`${currentCollection?.mintUrl}`}
                  target="_blank"
                  className="ml-2 px-1 border-primary text-primary border rounded"
                >
                  Mint
                </a>
              ) : (
                ''
              )}
            </p>

            <p className="text-center text-md-left">
              Protocol : <span className="text-light"> V1</span>
            </p>
            <p className="text-center text-md-left">
              Description :{' '}
              <span className="text-light">
                {' '}
                {currentCollection?.description}
              </span>
            </p>
            <div className="row pb-5">
              <div className="col-12 col-md-4 px-0 d-flex align-items-center justify-content-center justify-content-md-start">
                End Date :{' '}
                <span className="text-light pl-1">
                  {' '}
                  {currentCollection?.expire_date && (
                    <Countdown
                      date={new Date(parseInt(currentCollection?.expire_date))}
                      intervalDelay={0}
                      precision={3}
                      renderer={renderer}
                    />
                  )}
                </span>{' '}
              </div>
              <div className="col-12 col-md-8 px-0 pt-3 pt-md-0">
                {wallet &&
                  validUser(
                    wallet.accounts[0].address.toLowerCase(),
                    currentCollection?.creator_address
                  ) && (
                    <div className="row d-flex">
                      <div className="col-5">
                        <input
                          className=""
                          type="number"
                          placeholder="Duration in Seconds"
                          onChange={dateInputHandler}
                        />
                      </div>
                      <div className="col-7">
                        <button
                          className="btn setTimeBtn"
                          onClick={rewardsDurationHandler}
                          disabled={!duration ? true : false}
                        >
                          {btnLoading ? (
                            <div className="col-12 text-center">
                              <div className="spinner-border" role="status">
                                <span className="visually-hidden"></span>
                              </div>
                            </div>
                          ) : (
                            'SetRewardDuration'
                          )}
                        </button>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="row myNftDetailsRow ">
              <div className="col   text-center  mdBorder ">
                <p className="m-0 ">Your Staked</p>
                <p className="mt-2 text-light"> {myNftStaked ?? '0'}</p>
              </div>
              <div className="col text-center mdBorder">
                <p className="m-0 "> Total NFT Staked </p>
                <p className="mt-2 text-light">{totalNftStaked ?? '0'}</p>
              </div>
              <div className="col text-center">
                <p className="m-0 ">Pool Rate</p>
                <p className="mt-2 text-light">
                  {rewardsPerWeek
                    ? (rewardsPerWeek * 10 ** -decimal).toLocaleString(
                        'en-US',
                        {
                          maximumFractionDigits: 2
                        }
                      )
                    : 0}{' '}
                  {symbol && symbol}/Week
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="myHr mx-5 mb-4"></div>
      <div className="container pt-5">
        <div className="row d-flex justify-content-md-center justify-content-between">
          <div className="col-6 col-sm-6 col-md-2 col-lg-2 d-flex justify-content-center  mt-1  ">
            <button
              className={`nftdetailsBtn ${
                activeTab === 'stake' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('stake')}
            >
              Stake
            </button>
          </div>
          <div className="col-6 col-sm-6 col-md-2 col-lg-2 d-flex justify-content-center mt-1">
            <button
              className={`nftdetailsBtn ${
                activeTab === 'unStake' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('unStake')}
            >
              Unstake
            </button>
          </div>
          <div className="col-6 col-sm-6 col-md-2 col-lg-2 d-flex justify-content-center mt-1">
            <button
              className={`nftdetailsBtn ${
                activeTab === 'claim' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('claim')}
            >
              Claim
            </button>
          </div>
          <div className="col-6 col-sm-6 col-md-2 col-lg-2 d-flex justify-content-center mt-1">
            <button
              className={`nftdetailsBtn ${
                activeTab === 'info' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('info')}
            >
              Info
            </button>
          </div>
        </div>
      </div>
      {currentCollection?.collection_address ? (
        <div className="container pt-5">
          <div className="row pt-5 d-flex justify-content-center">
            <div className="col p-0">
              {activeTab === 'stake' ? (
                <Stake
                  stakingContractAddr={currentCollection?.contract_address}
                  collectionAddr={currentCollection?.collection_address}
                  setCallfunction={setCallfunction}
                  callFunction={callFunction}
                />
              ) : (
                ''
              )}
              {activeTab === 'unStake' ? (
                <Unstake
                  stakingContractAddr={currentCollection?.contract_address}
                  collectionAddr={currentCollection?.collection_address}
                  setCallfunction={setCallfunction}
                  callFunction={callFunction}
                />
              ) : (
                ''
              )}
              {activeTab === 'claim' ? (
                <Claim
                  stakingContractAddr={currentCollection?.contract_address}
                  collectionAddr={currentCollection?.collection_address}
                />
              ) : (
                ''
              )}
              {activeTab === 'info' ? (
                <Transactions
                  stakingContractAddr={currentCollection?.contract_address}
                  collectionAddr={currentCollection?.collection_address}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </section>
  );
}

import React, {useEffect, useState} from 'react';
import {ethers} from 'ethers';
import Moralis from 'moralis';

import {useConnectWallet} from '@web3-onboard/react';

import axios from 'axios';
import NftCard from './NftCard';
import {ToastContainer, toast} from 'react-toastify';
import {stakingContractInstByAddr} from '../../../contracts/contractInstance';

export default function Unstake({
  stakingContractAddr,
  collectionAddr,
  setCallfunction,
  callFunction
}) {
  const [{wallet}] = useConnectWallet();
  const [stakeNft, setStakeNft] = useState([]);
  const [selectedNfts, setSelectedNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nftLoader, setNftLoader] = useState(false);

  const getTotalUserNftCount = async () => {
    try {
      let stakingContractInst = await stakingContractInstByAddr(
        wallet?.provider,
        stakingContractAddr
      );

      const count = await stakingContractInst.collectionToUserToBalances(
        stakingContractAddr,
        wallet?.accounts[0].address
      );
    } catch (error) {
      console.log('🚀 ~ getTotalUserNftCount ~ error', error);
    }
  };
  useEffect(() => {
    if (wallet) {
      getTotalUserNftCount();
      getAllNftByContract();
      // getOwners();
      // handleStakeNftsss();
    }
  }, []);

  const handleStakeNftsss = (tokenIds) => {
    // console.log('🚀 ~ handleStakeNftsss ~ tokenIds', tokenIds);
    if (tokenIds) {
      const arr = tokenIds.map((i) => {
        return {
          token_address: collectionAddr,
          token_id: i
        };
      });
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'X-API-Key': process.env.REACT_APP_MORALIS_KEY
        },
        body: JSON.stringify({
          tokens: arr
        })
      };

      const data1 = new Promise((resolve, reject) => {
        try {
          fetch(
            `https://deep-index.moralis.io/api/v2/nft/getMultipleNFTs?chain=0x19`,
            options
          )
            .then((response) => response.json())
            .then((data) => {
              // console.log(data, 'iiiiiiiii');
              resolve(data);
            })
            .catch((err) => console.error(err));
        } catch (error) {
          console.error(error);
        }
      });
      return data1.then((val) => {
        // console.log("asynchronous logging has val:", val);
        return val;
      });
    }
  };

  let owners;

  // async function includeNextNPages(previous, numPages) {
  //   // Define results for the remaining pages
  //   const result = previous.result || [];

  //   // Always keep the latest response around
  //   let response = previous;

  //   // Loop through the remaining pages
  //   for (let i = 0; i < numPages; i++) {
  //     // Get the next page
  //     console.log(
  //       '🚀 ~ includeNextNPages ~ response.hasNext()',
  //       response.hasNext()
  //     );
  //     if (response.hasNext()) {
  //       response = await response.next(); //Best Practice: Always use next() to get the next page

  //       // Exit if we are on the last page already
  //       if (response.result.length == 0) break;

  //       // Add the results to the previous results
  //       result.push(...response.result);
  //       console.log('🚀 ~ includeNextNPages ~ result', result);
  //     }
  //   }

  //   // Apply the results to the last page
  //   const newResponse = {
  //     ...response, // Copy properties from the last response
  //     result: result // Set the result property with accumulated data
  //   };
  //   console.log('🚀 ~ includeNextNPages ~ newResponse', newResponse);

  //   // Return the response
  //   return newResponse;
  // }

  // async function getOwners() {
  //   owners = await Moralis.EvmApi.nft
  //     .getContractNFTs({
  //       address: collectionAddr,
  //       chain: '0x19', // Best Practice: Always specify chain
  //       limit: 100, // Best Practice: Always specify limit. (use the lowest limit you need for faster response)
  //       cursor: owners ? owners.cursor : null // Optional
  //     })
  //     .then((response) => includeNextNPages(response, 4));
  // }

  const getAllNftByContract = async () => {
    try {
      setNftLoader(true);
      let url = `${process.env.REACT_APP_API_URL}stake?collectionAddr=${collectionAddr}&user_address=${wallet?.accounts[0]?.address}`;
      let {data} = await axios.get(url);
      // console.log('🚀 ~ getAllNftByContract ~ data', data);
      let getId = data[0].collections.filter((e) => {
        return (
          e.collection_address?.toLowerCase() == collectionAddr?.toLowerCase()
        );
      });

      const allNfts = [];
      if (getId[0]?.token_id.length > 0) {
        const dividedArray = chunkArray(getId[0]?.token_id, 25);
        // console.log('🚀 ~ getAllNftByContract ~ dividedArray', dividedArray);
        for (let l = 0; l < dividedArray.length; l++) {
          const _stakeNftsData = await handleStakeNftsss(dividedArray[l]);
          allNfts.push(_stakeNftsData);
        }
        // console.log('🚀 ~ getAllNftByContract ~ allNfts', allNfts.flat());
        if (allNfts.length > 0) {
          setStakeNft(allNfts.flat());

          setNftLoader(false);
        }
      } else {
        setStakeNft([]);

        setNftLoader(false);
      }
    } catch (error) {
      setNftLoader(false);

      console.log('🚀 ~ getAllNftByContract ~ error', error);
    }
  };
  function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  const cardSelectHandle = (tokenId) => {
    if (!selectedNfts.includes(tokenId)) {
      setSelectedNfts([...selectedNfts, tokenId]);
    } else {
      const filter = selectedNfts.filter((id) => {
        return id != tokenId;
      });
      setSelectedNfts(filter);
    }
  };

  const handleUnStake = async () => {
    // local
    let params;
    try {
      setIsLoading(true);
      let stakingContractInst = await stakingContractInstByAddr(
        wallet?.provider,
        stakingContractAddr
      );
      const _stakingContractInst = await stakingContractInst.unStake(
        selectedNfts
      );
      const waitForUnstake = await _stakingContractInst.wait();
      if (waitForUnstake) {
        toast.success('Unstake Successful');

        let address = wallet?.accounts[0].address;
        let nfts = selectedNfts.toString();
        let url = `${process.env.REACT_APP_API_URL}stake?user_address=${address}&token_id=${nfts}&collection_address=${collectionAddr}`;
        let _removeFromDb = await axios.put(url);
        setSelectedNfts([]);
        getAllNftByContract();
        setIsLoading(false);
        setCallfunction(!callFunction);
      }
    } catch (error) {
      console.log('🚀 ~ handleUnStake ~ error', error);
      let _string = JSON.stringify(error);
      let _parse = JSON.parse(_string);
      if (_parse?.reason) {
        toast.error(_parse?.reason);
      } else {
        toast.error('Something went wrong!');
      }

      setIsLoading(false);
    }
  };

  // const check = async () => {};
  return (
    <>
      <ToastContainer />
      {/* <button onClick={check}>check</button> */}
      <div className="container myStakeContainer">
        <div className="row d-flex justify-content-center">
          {nftLoader ? (
            <div className="col   d-flex justify-content-center align-content-lg-center">
              <span class="loader"></span>
            </div>
          ) : (
            <>
              {stakeNft && stakeNft?.length > 0 ? (
                stakeNft?.map((item) => {
                  return (
                    <div className="col-3 myColDiv pb-3">
                      <NftCard
                        item={item}
                        selectedNfts={selectedNfts}
                        cardSelectHandle={cardSelectHandle}
                      />
                    </div>
                  );
                })
              ) : (
                <>
                  {wallet ? (
                    <>
                      {stakeNft?.length == 0 ? (
                        <div className="col">
                          <p className="text-center myShadowText">
                            {' '}
                            No NFT's Staked Yet!{' '}
                          </p>
                        </div>
                      ) : (
                        <div className="col d-flex justify-content-center align-content-lg-center">
                          <span class="loader"></span>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="col">
                      <p className="text-center myShadowText">
                        {' '}
                        Please Connect Wallet First{' '}
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex justify-content-center">
          <button
            onClick={handleUnStake}
            disabled={isLoading}
            className="StakeButton mt-md-3"
          >
            {isLoading ? (
              <div class="spinner-border text-info" role="status"></div>
            ) : (
              'UnStake'
            )}
          </button>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import Reward from './Reward';
import { useLocation, useParams } from 'react-router-dom';

export default function UpdateContract() {
  const params=useParams()
  const location=useLocation()
  // console.log('🚀 ~ UpdateContract ~ location', location)
  // console.log('🚀 ~ UpdateContract ~ params', params)
  // console.log('🚀 ~ UpdateContract ~ params', params?.addr)
  return (
    <>
    <section>

      <h1 className='text-center mb-0'>Update Contract</h1>
      <div className='text-center mb-5'>

      <a href="/pdf/update.pdf" target='_blank' className='text-center pt-4 deployUrl' >How to update contract</a>
      </div>
      <Reward addr={params?.addr} 
      collection={location?.state}
      />
    </section>
    </>
  );
}

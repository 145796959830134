export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    // if (file && file.type.match("image.*")) {

    // }
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (err) => {
      reject(err);
    };
  });
};

export const getJSON = function (url) {
  return new Promise(async (resolve, reject) => {
    var xhr = await new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'json';
    await xhr.send();
    xhr.onload = function () {
      var status = xhr.status;
      if (status === 200) {
        resolve(xhr.response);
      }
      // else {
      //   reject(status);
      // }
    };
  });
};

export const validUser = (currentUser, creator) => {
  if (currentUser == creator) {
    return true;
  }
  // aditya address  0xbBA8732Ee7c9e61Bc05Af01006785d0d6cd2471e
  // joseph addr 0x6bb81ca8ec2f7ccbb08a5b1df8c32781ef3e1c2d
  if (currentUser?.toLowerCase() == '0xbBA8732Ee7c9e61Bc05Af01006785d0d6cd2471e') {
    return true;
  } else {
    return false;
  }
};

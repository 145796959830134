import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  collection: []
};

export const nftSlice = createSlice({
  name: 'nftSlice',
  initialState,
  reducers: {
    saveCollection: (state, action) => {
      state.collection = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {saveCollection} = nftSlice.actions;
export default nftSlice.reducer;
